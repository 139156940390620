/** assets/css/ */

@import "variables.scss";
@import "bootstrap_variables.scss";

@import "util.scss";

/** the ~ allows you to reference things in node_modules  */
@import "~bootstrap/scss/bootstrap";

@import "../lib/owl/owl.carousel.min.css";
@import "../lib/owl/owl.theme.default.min.css";
@import "../lib/lity/lity.min.css";
@import "core.scss";
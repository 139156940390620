
@include respond-above(xs) {
  body {
  	
  }
}

@include respond-below(sm) {
  body {
  	
  	
  }
}

@include respond-above(md) {
  body {
  	
  	.border-right-white-md {
       border-right: 1px solid #ffffff !important;
     }
    
    .border-right-grey-md {
       border-right: 1px solid #e6ecf1 !important;
     }
    
    .left-text{
		left: -46px;
    }
  	
  }
}

@include respond-below(md) {
  body {
  	
  	.border-bottom-white-sm {
       border-bottom: 1px solid #ffffff !important;
     }
    
    .border-bottom-grey-sm {
       border-bottom: 1px solid #e6ecf1 !important;
     }
  	
  }
}



.darkblue-bg {
	
}

 .light-blue-bg {
 	  background-color: rgba(77, 144, 205, 0.15);
 }

.white {
	color: #ffffff;
}
.color2{
	color:#002850;
	
}
/**@import url("//fast.fonts.net/cssapi/35cb5bbc-4a0b-4f93-be6b-79d2380a9c53.css");*/

@font-face{
    font-family:"GeogrotesqueW01-Regular1185161";
    src:url("/fonts/77c5adea-c45f-4bd6-bc83-c171aa401eac.eot?#iefix");
    src:url("/fonts/77c5adea-c45f-4bd6-bc83-c171aa401eac.eot?#iefix") format("eot"),url("/fonts/38914808-31f7-4169-a962-60b41b189056.woff2") format("woff2"),url("/fonts/ae7eda9b-5729-4029-8ea8-1f4b2feb6595.woff") format("woff"),url("/fonts/f5e7d965-e646-4ea9-8b21-bf17e3e5de42.ttf") format("truetype");
}
@font-face{
    font-family:"Geogrotesque W01 Medium1185174";
    src:url("/fonts/40bc667a-fe6b-4600-8320-f66daeb25b06.eot?#iefix");
    src:url("/fonts/40bc667a-fe6b-4600-8320-f66daeb25b06.eot?#iefix") format("eot"),url("/fonts/dd501f4a-1a91-4d73-b4cf-de3ba26bd793.woff2") format("woff2"),url("/fonts/149719fe-5b81-4b67-a95a-3a5246a84032.woff") format("woff"),url("/fonts/4c464889-c33e-4b8c-b182-17a32eb1f7a8.ttf") format("truetype");
}
@font-face{
    font-family:"Neue Haas Unica W01 Light";
    src:url("/fonts/001e59a9-197e-4fb1-9e72-cef21b186c03.eot?#iefix");
    src:url("/fonts/001e59a9-197e-4fb1-9e72-cef21b186c03.eot?#iefix") format("eot"),url("/fonts/e5716290-d41e-4c97-a27c-7a20a46ddf45.woff2") format("woff2"),url("/fonts/b681571f-aab2-4935-a99c-6ee1063ce638.woff") format("woff"),url("/fonts/1d88a9b0-aac0-40df-baf7-a630cd19cc5d.ttf") format("truetype");
}
@font-face{
    font-family:"Neue Haas Unica W01 Regular";
    src:url("/fonts/0ebd7648-5847-404a-a88a-28a353c0921c.eot?#iefix");
    src:url("/fonts/0ebd7648-5847-404a-a88a-28a353c0921c.eot?#iefix") format("eot"),url("/fonts/e0d80810-f7e3-4fea-8c57-ef8116f0465d.woff2") format("woff2"),url("/fonts/27645c8a-608b-4abf-a2f1-c4407b576723.woff") format("woff"),url("/fonts/bd3b3439-8eff-445c-80ed-87064138ca7d.ttf") format("truetype");
}
@font-face{
    font-family:"Neue Haas Unica W01 Medium It";
    src:url("/fonts/56b738a7-54b9-4e8c-a470-d96972ab3303.eot?#iefix");
    src:url("/fonts/56b738a7-54b9-4e8c-a470-d96972ab3303.eot?#iefix") format("eot"),url("/fonts/2418a824-3af2-42d7-a62e-f7a77cf487d1.woff2") format("woff2"),url("/fonts/86b76db5-9343-473a-a106-60683cec7626.woff") format("woff"),url("/fonts/2b883332-01dc-4b55-ac6a-d24c84f0e334.ttf") format("truetype");
}

.light, .thin {
	font-family: "Neue Haas Unica W01 Light";
}

.bold, .strong, strong {
	font-family: "Neue Haas Unica W01 Medium It";
}

#site.baymevbm:not(.vbw) {
	h1, .h1, h2, .h2, h3, .h3 {
		font-family: "GeogrotesqueW01-Regular1185161";
	}
}

.btn .icon:after {
	content: "";
	background-image: url('/img/icons/btn_icon.png');
	background-repeat: no-repeat;
	background-size: 100%;
	width: 9px;
	height: 15px;
	display: inline-block;
	margin-left: 15px;
}


.light {
	font-family: "Neue Haas Unica W01 Light";
}

/**

*/

@import "header/header.scss";
@import "footer/footer.scss";

.icon {
	/**@extend .d-flex, .flex-row, .mb-2;*/

	align-items: center;
	position: relative;
	padding-left: 40px;
}

.icon:before {
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	min-width: 20px;
	height: 21px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	margin-top:2px;
}

.icon-phone:before {
	background-image: url('/img/icons/phone.png');
}

.icon-mobile:before {
	background-image: url('/img/icons/mobile.png');	
}

.icon-email:before {
	background-image: url('/img/icons/email.png');	
}

a.icon-phone, a.icon-mobile {
	color: inherit;
	
}

.link-arrow {
	position: relative;
	padding-right: 40px;
}

.link-arrow:after {
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	min-width: 20px;
	height: 15px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background-image: url('/img/icons/link_arrow.png');	
}


a.white {
	@include hover {
    		color: white;
	}
}

ul.checklist {
	list-style: none;
	padding: 0;
	
}

ul.checklist li {
	position: relative;
	padding-left: 50px;
	@extend .mb-4;
}

ul.checklist li:before {
	content: "";
	background-repeat: no-repeat;
	width: 24px;
	height: 18px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}

ul.checklist-white li:before {
	background-image: url('/img/icons/check_white.png');
}

ul.checklist-blue li:before {
	background-image: url('/img/icons/check_blue.png');
}

ul:not(.checklist-white):not(.checklist-blue):not(.no-list) {
	list-style: none;
	padding-left: 0;
}





.vertical-middle-align {
    display: flex;
    align-items: center;
}



$grid-breakpoints: (
    xs: 0,
    sm: 768px,
    md: 1074px,
    lg: 1280px,
    xl: 1530px
);

$container-max-widths: (
        sm: 628px,
        md: 964px,
        lg: 1020px,
        xl: 1270px
);

$spacer: 1rem !default;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 5),
  8: ($spacer * 8)
);
 
 
$font-family-sans-serif: "Neue Haas Unica W01 Regular", sans-serif;
/**$headings-font-family: "Neue Haas Unica W01 Light";*/

$font-weight-normal:          400 !default;
$font-weight-bold:            $font-weight-normal;
$font-weight-bolder:			 $font-weight-normal;


$enable-rounded: false;

$btn-border-width: 	0;
$btn-font-size: 		18px;
$btn-padding-y:	 	23px;
$btn-padding-x:		60px;

$baymevbm: $baymevbmBlue;
$vbw: $vbwBlue;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "baymevbm":   $baymevbm,
    "vbw":  		  $vbw
  ),
  $theme-colors
);

$link-color: #5687B9;
$link-hover-color: $link-color;
$link-hover-decoration: none;
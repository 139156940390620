.module-job-listing-detail .header {
	background-color: rgba($baymevbmBlue, 0.15);
}

.module-job-listing-detail .header {
	padding-top: $logoHeight/2;
}

#site.baymevbm:not(.vbw) .module-job-listing-detail .header {
	color: $baymevbmBlue;
}

#site.vbw .module-job-listing-detail .header {
	color: #12416E;
}

#site.baymevbm:not(.vbw) .color {
	color: #16466C;
}

#site.vbw .color {
	color: #16466C;
}


.module-job-listing-detail .portal-title {
	font-size: 18px;
	position: absolute;
	left: $logoWidth;
	top: 0;
	transform: translateY(-100%);
	
	@include respond-below(sm) {
		transform: translateY(0);
		position: static;
		margin-bottom: 20px;
	}
	
}

#site.vbw.baymevbm .module-job-listing-detail .portal-title {
	left: $logoWidth * 2;
}

#site.baymevbm:not(.vbw) .module-job-listing-detail .portal-title {
	color: #042B52;
}

#site.vbw .module-job-listing-detail .portal-title {
	color: #012850;
}


.module-job-listing-detail .job-details {
	position: absolute;
	bottom: 0;
	left: $grid-gutter-width/2;
	transform: translateY(50%);
}

.module-job-listing-detail .job-details .detail {
	background-color: #88B4DD;
	@extend .white, .py-3, .px-4, .float-left, .mr-3, .strong;
}

#site.vbw .module-job-listing-detail .job-details .detail {
	background-color: #6F99C4;
}

.module-job-listing-detail .offer {
	background-color: $darkBlue;
	color: white;
	position: relative;
}

.module-job-listing-detail .offer .col-right {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 50%;
	background-image: url('/img/samples/job_detail.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.module-job-listing-detail .download {
	background-color: #DFEBF6;
	@extend .py-4;
}

.module-job-listing-detail .download .widget {
	position: relative;
	padding-left: 80px;
	cursor: pointer;
	color: inherit;
}

.module-job-listing-detail .download .widget:before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 31px;
	height: 30px;
	background-image: url('/img/icons/download_blue.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.module-job-listing-detail .download .widget .info {
	@extend .light;
}

.module-job-listing-detail .download .widget .job-title {
	@extend .strong;
}


.module-job-listing-detail .application {
	@extend .py-7;
}

.module-job-listing-detail .application .col-right .box {
	background-color: rgba(#4D90CD, 0.17);
	@extend .px-6, .py-5;
	height: 100%;
	@include respond-below(md) {
		margin-top: 40px;
	}
}


.footer-navigation {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;

}

.footer-navigation li {
    float: left;
    display: block;
    padding: 0 20px;
    
    @include respond-below(md) {
    		padding-left: 0;
	}
}

.footer-navigation li:first-child {
    padding-left: 0;
}
.footer-navigation a {
    color: white;
}

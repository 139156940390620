header {
	background-color: $darkBlue;
	color: white;
}

header {
	
	@extend .container-fluid, .pl-sm-0, .pr-sm-0, .pt-5;
	
}

header .inner {
	
	@extend .container;
	
	@include respond-below(sm) {
		padding-left: 0;
		padding-right: 0;
	}
	
}

header .outer {
	position: relative;
}

header .col-left {
	background-color: $headerBlue;
	@extend .col-md-6;
}

header .col-right {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $headerBlue;
	@extend .col-md-6, .d-none, .d-md-flex;
}

header .logo-row {
	@extend .mt-5;
	
	min-height: 120px;
}

header .logo {
	width: $logoWidth;
	height: $logoHeight;
}


.header-top .logos {
	
	position: relative;
	z-index: 2;
	left: -($logoWidth/2);
	bottom: -($logoHeight/2);
	
	@include respond-below(sm) {
		left: 15px;
	}
	
	@include respond-above(sm) {
		left: -($logoWidth/2);
	}
	
}


#site.baymevbm:not(.vbw) #logo_vbw {
	display: none;
}
#site.vbw:not(.baymevbm) #logo_baymevbm {
	display: none;
}

#site.baymevbm.vbw #logo_vbw {
	@extend .pr-1;
}

#site.baymevbm.vbw #logo_baymevbm {
	@extend .pl-1;
}

#site #below_header > * {
	@extend .container-fluid, .pl-sm-0, .pr-sm-0;
}

/**
#site.baymevbm.vbw #below_header > * > * {
	@include respond-above(sm) {
		@extend .offset-sm-1, .col-sm-10;
	}
}
*/

#site.baymevbm:not(.vbw) #below_header > * ,
#site.vbw:not(.baymevbm) #below_header > *
{
	@extend .container;	
}
@import "modules/job_listing_detail.scss";


/**



*/
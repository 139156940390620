
$darkBlue:                   #002850 !default;

$headerBlue:					rgba(#2D69A5, 0.8);

$baymevbmBlue:				#4D90CD;
$vbwBlue:					#5687B9;

$belowHeaderBlue:			$vbwBlue;			

$logoWidth:					126px;			
$logoHeight:					126px;

$footerTopBlue:				rgba(#447AB2, 0.15);
$footerTopBlueBaymeVbm:		rgba(#4D90CD, 1);
$footerTopBlueVbw:			rgba(#5D8BBB, 1);
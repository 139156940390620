footer {
	
}

#site.baymevbm.vbw footer #footer_top {
	background-color: $footerTopBlue;
	color: #012650;
}

#site.baymevbm:not(.vbw) footer #footer_top {
	background-color: $footerTopBlueBaymeVbm;
}

#site.vbw:not(.baymevbm) footer #footer_top {
	background-color: $footerTopBlueVbw;
}

footer #footer_top .inner {
	@extend .container, .pt-3, .pb-3;
	
}


footer #footer_bottom {
	background-color: $darkBlue;
	color: white;
}

footer #footer_bottom .inner {
	@extend .container, .pt-3, .pb-3;
}

footer .col-left {
	@extend .col-9, .pl-0, .d-flex;
	@include respond-below(md) {
		flex-direction: column-reverse;
	}
}

footer .col-right {
	@extend .col-3, .pr-0;
}


footer .footer-item {
	@include respond-below(md) {
		display: block;
		float: none;
	}
}

footer .footer-navigation .main {
	@extend .footer-item;
}

